/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import EmailSVG from "../images/envelope-regular.svg";
import GreenShieldSVG from "../images/shield.svg";
import BillSVG from "../images/bill.svg";
import Header from "./header";
import BlueCloudSVG from "../images/cloud-computing-blue.svg";
import RocketShip from "../images/rocket.svg";
import CertSVG from "../images/certificate.svg";
import cloudPeople from "../images/cloud-people.jpg";

import "./layout.css";
import Footer from "./Footer";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className="font-sans antialiased bg-gray-100">
      <Header siteTitle={data.site.siteMetadata.title} />
      <main className="relative min-h-screen overflow-hidden bg-gray-800">
        {children}
      </main>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
